import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  GoogleAnalyticsInitializer,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
} from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root',
})
export class GatrackingService {
  settingstoken = inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN);
  tagFn = inject(NGX_GTAG_FN);
  document = inject(DOCUMENT);
  cookie = inject(CookieService);

  initialise() {
    GoogleAnalyticsInitializer(this.settingstoken, this.tagFn, this.document)();
  }

  getConsentCookieStatus(): boolean | null {
    return this.cookie.check('tracking_consent')
      ? this.cookie.get('tracking_consent') === '1'
        ? true
        : false
      : null;
  }

  setConsentCookieStatus(flag: boolean) {
    this.cookie.set('tracking_consent', flag ? '1' : '0', 180);
  }
}
